import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  scrollIntoView(element?: HTMLElement) {
    if (!!element) {
      element.parentElement.scrollIntoView({ behavior: 'smooth' });
      this.compensateBaHeaderScroll();
    }
  }

  scrollIntoViewWithDelay(element?: HTMLElement) {
    if (!!element) {
      element.parentElement.scrollIntoView({ behavior: 'smooth' });
      this.compensateBaHeaderScroll(true);
    }
  }

  private compensateBaHeaderScroll(withDelay = false) {
    const scroll = () => {
      //     // Height of BA Header
      this.document.defaultView.scrollBy({ top: -108 });
    };
    if (withDelay) {
      setTimeout(() => scroll(), 300);
    } else {
      scroll();
    }
  }
}
