<article
  #studiDetails
  *ngIf="studienangebot"
  class="ba-layout-tile studisu-tile"
  [attr.aria-labelledby]="'title_' + studienangebot.id"
  [id]="'angebot-' + studienangebot.id"
>
  <h2 class="h5 titel" id="title_{{ studienangebot.id }}">
    <a
      routerLink="./studienangebot/{{ studienangebot.id }}"
      class="h5 ba-link-primary"
      [queryParams]="getStudienangebotQueryParams()"
      [innerHTML]="breakUpLongWords(studienangebot.studiBezeichnung)"
    ></a>
  </h2>
  <div class="position-absolute end-0 top-0 me-2 mt-2 d-print-none">
    <button
      *ngIf="showRemoveFromMerklist"
      (click)="merklistService.removeFromMerklist(studienangebot.id)"
      attr.aria-label="Angebot {{ studienangebot.studiBezeichnung }} von der Merkliste entfernen"
      data-bs-toggle="popover"
      data-bs-placement="top"
      data-bs-content="Angebot von der Merkliste löschen"
      class="ba-btn ba-btn-ghost ba-btn-icon-only ba-icon-trash ba-btn-red-ghost fs-5"
    ></button>
    <ba-bub-bookmark
      *ngIf="!showRemoveFromMerklist"
      [id]="studienangebot.id"
      [title]="studienangebot.studiBezeichnung"
      [large]="false"
      [gemerkteIds]="merklistService.getMerklist() | async"
      (aufMerklisteHinzufuegen)="merklistService.addToMerklist(studienangebot.id)"
      (vonMerklisteEntfernen)="merklistService.removeFromMerklist(studienangebot.id)"
    ></ba-bub-bookmark>
  </div>
  <div
    *ngIf="studienangebot.studiInhalt as inhalt"
    class="beschreibung"
    [innerHTML]="formatText(inhalt)"
  ></div>
  <div class="neben-infos">
    <div class="neben-info studienanbieter">
      <div aria-hidden="true"><i class="ba-icon studisu-icon-diploma-full"></i></div>
      <div>
        <div class="fw-bold">Studienanbieter:</div>
        <div>{{ studienangebot.bildungsanbieterName }}</div>
        <div class="signet">
          <ba-studisu-signet
            [hasSignet]="studienangebot.logo?.externalURL?.length > 0"
            [url]="studienangebot.logo?.externalURL"
            [id]="'signet_' + studienangebot.bildungsanbieterId"
          ></ba-studisu-signet>
        </div>
      </div>
    </div>
    <div *ngIf="studienangebot.studienort as studienort" class="neben-info studienort">
      <div aria-hidden="true"><i class="ba-icon ba-icon-location-full"></i></div>
      <div>
        <div class="fw-bold">Studienort:</div>
        <address *ngIf="studienort.ort; else ohneOrt">
          <div *ngIf="studienort.strasse">{{ studienort.strasse }}</div>
          <div *ngIf="studienort.ort">
            <span *ngIf="studienort.postleitzahl">{{ studienort.postleitzahl }}</span>
            {{ studienort.ort }}
          </div>
        </address>
        <ng-template #ohneOrt>
          <div>{{ studienangebot?.ohneStudienortText }}</div>
        </ng-template>
        <div
          *ngIf="studienangebot.abstaende?.length > 0"
          class="distanz-chips d-flex gap-1 flex-wrap"
          role="list"
        >
          <ba-bub-distanz-chip
            *ngFor="let ortAbstand of studienangebot.abstaende"
            [distanz]="ortAbstand.abstand"
            [ort]="ortAbstand.suchort"
          >
          </ba-bub-distanz-chip>
        </div>
      </div>
    </div>
    <div *ngIf="studienangebot?.studienort?.bundesland" class="neben-info bundesland">
      <div aria-hidden="true"><i class="ba-icon studisu-icon-region-full"></i></div>
      <div>
        <span class="fw-bold">Bundesland:</span><br />{{ studienangebot.studienort.bundesland }}
      </div>
    </div>

    <div
      *ngIf="
        studienangebot?.studienort?.staat !== 'Deutschland' &&
        flaggenService.laenderBildVerzeichnis(studienangebot.studienort.staat) !== null
      "
      class="neben-info studienland"
    >
      <div aria-hidden="true"><i class="ba-icon studisu-icon-region-full"></i></div>
      <div id="ergebnisseiteBildungsland">
        <span class="fw-bold">Land:</span><br />
        <img
          class="studienortLandBild"
          src="{{ flaggenService.laenderBildVerzeichnis(studienangebot.studienort.staat) }}"
          alt="Flagge von {{
            flaggenService.laenderNameFormatiert(studienangebot.studienort.staat)
          }}"
        />
        {{ flaggenService.laenderNameFormatiert(studienangebot.studienort.staat) }}
      </div>
    </div>
  </div>
  <div class="quick-infos">
    <div class="studientests" *ngIf="studienangebot.hasStudiencheckOsa()">
      <span
        *ngFor="let link of studienangebot.externalLinks"
        class="logo {{ link.name }}"
        [title]="link.tooltip"
        [attr.aria-label]="link.tooltip"
      >
        <img [src]="'./assets/images/' + link.name + '.png'" alt="Logo von {{ link.name }}" />
      </span>
    </div>
    <div
      role="list"
      class="tag-liste d-flex gap-2 flex-wrap ng-star-inserted"
      aria-label="Stichwörter"
    >
      <ba-bub-chip
        [title]="studienangebot.studientyp.label"
        class="studientyp"
        [icon]="iconForStudientyp(studienangebot.studientyp)"
        [name]="'Studientyp'"
      >
        {{ studienangebot.studientyp.label | sgMaxLength }}
      </ba-bub-chip>
      <ba-bub-chip
        [title]="studienangebot.studienform.label"
        class="studienform"
        [icon]="iconForStudienform(studienangebot.studienform)"
        [name]="'Studienform'"
      >
        {{ studienangebot.studienform.label | sgMaxLength }}
      </ba-bub-chip>
      <ba-bub-chip
        [title]="studienangebot.hochschulart.label"
        class="hochschulart"
        [icon]="'studisu-icon-hochschulart-dark'"
        [name]="'Hochschulart'"
      >
        {{ studienangebot.hochschulart.label | sgMaxLength }}
      </ba-bub-chip>
      <ng-container *ngFor="let studienfach of studienangebot.studienfaecher">
        <ba-bub-chip
          *ngIf="studienfach"
          [title]="studienfach"
          class="studienfach"
          [icon]="'studisu-icon-studienfach'"
          [name]="'Studienfach ' + studienfach"
        >
          {{ studienfach | sgMaxLength }}
        </ba-bub-chip>
      </ng-container>
    </div>
  </div>
  <a
    [attr.aria-label]="'Zur Detailseite des Studiengangs' + studienangebot.studiBezeichnung"
    class="detail-nav-btn ba-btn ba-btn-primary ba-btn-icon ba-icon-chevron-right-after"
    routerLink="./studienangebot/{{ studienangebot.id }}"
    [queryParams]="getStudienangebotQueryParams()"
    id="detailbutton_{{ studienangebot.id }}"
    >Zur Detailseite</a
  >
</article>
